import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import AppLayouts from '../Layouts/AppLayouts';

const YOUTUBE_API_KEY = 'AIzaSyAqd_I8T49tFT13I7WXh6cinFhMMpimZWc';
const CHANNEL_ID = 'UCR5KBvywfC1NKfIPPN3pGsw';
const MAX_RESULTS = 10;

function Podcast() {
    const [videos, setVideos] = useState([]);
    const [filteredVideos, setFilteredVideos] = useState([]);
    const [category, setCategory] = useState('All');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isCategoryLoading, setIsCategoryLoading] = useState(false); // For category switch loading

    useEffect(() => {
        const fetchYouTubeVideos = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `https://www.googleapis.com/youtube/v3/search`,
                    {
                        params: {
                            part: 'snippet',
                            channelId: CHANNEL_ID,
                            maxResults: MAX_RESULTS,
                            order: 'date',
                            type: 'video',
                            key: YOUTUBE_API_KEY,
                        },
                    }
                );
                const fetchedVideos = response.data.items || [];
                setVideos(fetchedVideos);
                setFilteredVideos(fetchedVideos); // Default: show all
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchYouTubeVideos();
    }, []);

    const handleCategoryChange = (selectedCategory) => {
        setCategory(selectedCategory);
        setIsCategoryLoading(true); // Show category switch loading
        setTimeout(() => {
            if (selectedCategory === 'All') {
                setFilteredVideos(videos);
            } else if (selectedCategory === 'PoTW') {
                setFilteredVideos(videos.filter((video) =>
                    video.snippet.title.includes('PoTW')
                ));
            } else if (selectedCategory === 'Sam Hendy') {
                setFilteredVideos(videos.filter((video) =>
                    video.snippet.title.includes('Sam Hendy')
                ));
            }
            setIsCategoryLoading(false); // Hide loading after filtering
        }, 500); // Add slight delay for smooth animation
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }).format(date);
    };

    if (loading) {
        return (
            <AppLayouts title="Podcast">
                <motion.div
                    className="text-center py-10"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                >
                    Loading podcasts...
                </motion.div>
            </AppLayouts>
        );
    }

    if (error) {
        return (
            <AppLayouts title="">
                <div className="text-center py-10 text-red-500">Error: {error}</div>
            </AppLayouts>
        );
    }

    return (
        <AppLayouts title="">
            <div className="max-w-4xl mx-auto md:p-10 dark:bg-gray-900 bg-gray-100 my-5 md:dark:bg-gray-800 md:bg-white mb-10 rounded-xl">
                <div className="p-5">
                    <h2 className="text-xl md:text-2xl font-bold text-gray-800 dark:text-gray-100 mb-4">
                        Podcast
                    </h2>

                    {/* Category Selector */}
                    <div className="flex justify-center mb-6">
                        <button
                            onClick={() => handleCategoryChange('All')}
                            className={`px-4 py-2 rounded-l-md ${category === 'All' ? 'bg-blue-500 text-white' : 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-100'}`}
                        >
                            All
                        </button>
                        <button
                            onClick={() => handleCategoryChange('PoTW')}
                            className={`px-4 py-2 ${category === 'PoTW' ? 'bg-blue-500 text-white' : 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-100'}`}
                        >
                            PoTW
                        </button>
                        <button
                            onClick={() => handleCategoryChange('Sam Hendy')}
                            className={`px-4 py-2 rounded-r-md ${category === 'Sam Hendy' ? 'bg-blue-500 text-white' : 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-100'}`}
                        >
                            Sam Hendy
                        </button>
                    </div>

                    {/* Videos Grid */}
                    {isCategoryLoading ? (
                        <motion.div
                            className="text-center py-10"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                        >
                            Switching category...
                        </motion.div>
                    ) : (
                        <motion.div
                            className="grid grid-cols-1 md:grid-cols-2 gap-6"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                        >
                            {filteredVideos.map((video) => (
                                <motion.div
                                    key={video.id.videoId}
                                    className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden"
                                    initial={{ scale: 0.95 }}
                                    animate={{ scale: 1 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <div className="aspect-w-16 aspect-h-9">
                                        <iframe
                                            className="w-full h-full"
                                            src={`https://www.youtube.com/embed/${video.id.videoId}`}
                                            title={video.snippet.title}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                    <div className="p-4">
                                        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                                            {video.snippet.title}
                                        </h3>
                                        <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                                            {video.snippet.description}
                                        </p>
                                        <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                                            Published on: {formatDate(video.snippet.publishedAt)}
                                        </p>
                                    </div>
                                </motion.div>
                            ))}
                        </motion.div>
                    )}
                </div>
            </div>
        </AppLayouts>
    );
}

export default Podcast;
